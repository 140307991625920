import { useApolloClient } from '@apollo/client';
import { getRacingQuery, getReloadRacingQuery, getQueryVariables, getResultsPageMeeting } from '../DataQuery';
import { apiErrorCallback } from '../../../Common/graphqlFetch';
import { ClearBetRaces, ClearBetByBetTypeAndRacesAndRunner} from '../../Common/RacingBetline';
import { initAlupData, getNextDefinedRace } from '../../Common/Common';
import { getAlupBetTypeList } from '../../Common/AlupPageBase';
import { isLocalMeeting } from '../../../Common/home-common';

const useLoadMeeting = (contentRef, setContent) => {
    const { query } = useApolloClient();

    const reloadMeeting = (callbackFunc, isBetShare) => {
        clearTimeout(window.RCMeetingFetchTimer)
        return Promise.all([query({
            query : getRacingQuery(),
            variables: getQueryVariables({
                page: isBetShare ? null : contentRef.current.page,
                date: isBetShare ? null :contentRef.current.date,
                venue: isBetShare ? null :contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then( async ([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if(isBetShare){
                callbackFunc(results.data)
                return
            }
            if ( results?.data?.activeMeetings ) {
                contentRef.current.meetingList = results.data.activeMeetings;
            }
            if ( results?.data?.raceMeetings?.[0] ) {
                let raceMeetings = results.data.raceMeetings.map(mtg => {
                    return {
                        ...mtg,
                        foPools: [],
                        resPools: []
                    }
                })
                if(['RESULTS', 'HOME_RESULTS', 'JKC', 'TNC'].includes(contentRef.current.page)){
                   await loadResultsMeeting(raceMeetings[0])
                }else{
                    contentRef.current.meeting = raceMeetings[0];
                }
            }
            if ( callbackFunc ) {
                callbackFunc(results);
            }
            else {
                setContent({ ...contentRef.current });
            }
        }).catch(error => {console.log(error), apiErrorCallback(error, ()=> {reloadMeeting(callbackFunc)}, {timer: window.RCMeetingFetchTimer})});
    }

    const autorRefreshMeeting = (callbackFunc) =>{
        clearTimeout(window.RCMeetingFetchTimer)
        return Promise.all([query({
            query : getRacingQuery(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then( async ([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if ( results?.data?.activeMeetings ) {
                contentRef.current.meetingList = results.data.activeMeetings;
            }
            if ( results?.data?.raceMeetings?.[0] ) {
                let raceMeetings = results.data.raceMeetings.map(mtg => {
                    const oldMeeting = contentRef.current.meeting.id == mtg.id ? contentRef.current.meeting : mtg
                    return {
                        ...mtg,
                        pmPools: oldMeeting.pmPools,
                        foPools: oldMeeting.foPools || [],
                        resPools: oldMeeting.resPools || []
                    }
                })
                const isNewMeerting = contentRef.current.meeting.id != raceMeetings[0].id
                if(['RESULTS', 'HOME_RESULTS', 'JKC', 'TNC'].includes(contentRef.current.page)){
                   await loadResultsMeeting(raceMeetings[0])
                }else{
                    contentRef.current.meeting = raceMeetings[0];
                }
                contentRef.current.venue = raceMeetings[0].venueCode
                contentRef.current.date = raceMeetings[0].date
                if(isNewMeerting) {
                    contentRef.current.raceNo = getNextDefinedRace(contentRef.current.page, 0, contentRef.current.meeting)
                }
            }
            if ( callbackFunc ) {
                callbackFunc(results);
            }
            else {
                setContent({ ...contentRef.current });
            }
        }).catch(error => {console.log(error), apiErrorCallback(error, ()=> {reloadMeeting(callbackFunc)}, {timer: window.RCMeetingFetchTimer})});
    }

    const loadResultsMeeting = (raceMeeting) =>{
        return Promise.all([query({
            query : getResultsPageMeeting(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if(results?.data?.raceMeetings?.[0]){
                contentRef.current.meeting = {
                    ...raceMeeting,
                    ...results.data.raceMeetings[0]
                }
            }
        })
        
    }

    const reloadMeetingStatus = (callbackFunc) => {
        clearTimeout(window.RCMeetingFetchTimer)
        return Promise.all([query({
            query : getReloadRacingQuery(),
            variables: getQueryVariables({
                page: contentRef.current.page,
                date: contentRef.current.date,
                venue: contentRef.current.venue
            }),
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true
        })])
        .then(([results]) => {
            if(results.errors){
                throw new Error(results.errors?.[0]?.message)
            }
            if(callbackFunc){
                callbackFunc(results)
                return 
            }
            if ( results?.data?.raceMeetings?.[0] ) {
                contentRef.current.meeting.status = results.data.raceMeetings[0].status;
                contentRef.current.meeting.currentNumberOfRace = results.data.raceMeetings[0].currentNumberOfRace;
                contentRef.current.meeting.totalInvestment = results.data.raceMeetings[0].totalInvestment;
                let isLocalMtg = isLocalMeeting(contentRef.current.venue)
                results.data.raceMeetings[0].races.forEach(race => {
                    let tRace = contentRef.current.meeting.races.filter(x=>x.no==race.no)[0];
                    if ( tRace ) {
                        tRace.status = race.status;
                        if(["CLOSED", "VOIDED", "RESULT", "ABANDONED"].includes(tRace.status.toUpperCase())){
                            // ClearBetSels();
                            ClearBetRaces(race.no);
                            if(contentRef.current.alupData.length>0){
                                const lastRaceNo = Math.max(... contentRef.current.meeting.races.map(x=>x.no))
                                if(tRace.no != lastRaceNo){
                                    contentRef.current.alupData = contentRef.current.alupData?.filter(x => x.raceNo != tRace.no) ;
                                    if ( contentRef.current.alupData.length <=6 ) {
                                        let fSet =  contentRef.current.config['ALUP_FORMULA'][contentRef.current.alupData.length];
                                        contentRef.current.alupFormula = fSet[0];
                                    }
                                    if(contentRef.current.alupData.length == 0){
                                        const curRaceNo = getNextDefinedRace(contentRef.current.page, contentRef.current.raceNo, contentRef.current.meeting)
                                        contentRef.current.alupData.push(initAlupData(contentRef, curRaceNo, getAlupBetTypeList( contentRef.current.page)))
                                    }

                                }
                            }
                        }
                        tRace.runners = race.runners
                    }
                });
            }
            setContent({ ...contentRef.current });
        }).catch(error => {console.error(error), apiErrorCallback(error, ()=> {reloadMeetingStatus(callbackFunc)}, {timer: window.RCMeetingFetchTimer})});
    }

    const updatePushRunner = (results) => {
        let isLocal  = isLocalMeeting(contentRef.current.venue)
        if(results?.data?.raceMeetings?.[0]){
            results.data.raceMeetings[0].races.forEach(race => {
                let tRace = contentRef.current.meeting.races.filter(x=>x.no==race.no)[0];
                if ( tRace ) {
                    tRace.runners = race.runners.map(r =>{
                        const tRunner = tRace.runners.find(i => i.id == r.id)
                        if(tRunner && (!isLocal || tRunner.status.toUpperCase() != 'STANDBY')){
                            return {
                                ...r,
                                status: tRunner.status
                            }
                        }
                        return r
                    })
                }
            });
        }
    }

    return {
        reloadMeeting,
        autorRefreshMeeting,
        reloadMeetingStatus,
        updatePushRunner
    }
}

export default useLoadMeeting